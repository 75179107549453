import API from "../../../hooks/api";
import {
  CUSTOMER_DEACTIVATE_URL,
  CUSTOMER_LIST_URL,
  CUSTOMER_UPDATE_BLOCK_STATUS_URL,
} from "../../../utils/Endpoints";
import {
  Customer,
  CustomerBlockStatusRequestData,
  CustomerData,
  filters,
  message,
} from "./ScreenHelper";
const { REACT_APP_BASE_URL } = process.env;

export const getAllCustomers = async (
  data: filters,
  callBack: (
    success: boolean,
    data: CustomerData,
    errorMsg: string | null
  ) => void = () => {}
) => {
  await API.get(REACT_APP_BASE_URL + CUSTOMER_LIST_URL, {
    params: {
      page: data.page ?? 1,
      size: data.pageSize ?? 10,
      searchText: data.search,
      loadDeactivatedCustomersOnly: data.loadDeactivatedCustomersOnly ?? false,
      loadAllDocuments: data.loadAllDocuments,
    },
  })
    .then((res) => {
      const data: CustomerData = res?.data;
      callBack(true, data, null);
      return res.data;
    })
    .catch((err) => {
      callBack(false, null, err);
    });
};

export const deactivateCustomer = async (
  govId: string,
  reason: string,
  callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
  await API.put(REACT_APP_BASE_URL + CUSTOMER_DEACTIVATE_URL, {
    customerId: govId,
    permanent: false,
    deactivationReason: reason,
  })
    .then((res) => {
      callBack(true, null);
      return res.data;
    })
    .catch((err) => {
      callBack(false, err);
    });
};

/**
 * Calls Update customer block status.
 * @param payload customer block status payload
 * @param callBack - callback function update the screen.
 */
export const updateCustomerBlockStatus = async (
  payload: CustomerBlockStatusRequestData,
  callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
  await API.put(REACT_APP_BASE_URL + CUSTOMER_UPDATE_BLOCK_STATUS_URL, payload)
    .then((res) => {
      callBack(true, null);
      return res.data;
    })
    .catch((err) => {
      callBack(false, err);
    });
};
