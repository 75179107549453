import React, { useEffect, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";
import {
  blockCustomerPopup,
  callRequest,
  Customer,
  CustomerData,
  deactivateCustomerPopup,
  exportData,
  filters,
  paginationElemnts,
  showCustomerTrackingPopup,
  showDetails,
  message
} from "./hook/ScreenHelper";

import { useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import Moment from "react-moment";

export const Customers: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [inputs, setInputs] = useState<filters>({ page });
  const [filters, setFilters] = useState<filters>({
    page,
    loadAllDocuments: false,
  });
  const [data, setData] = useState<CustomerData>(null);
  const [timer, setTimer] = useState(null);
  const payoutTableMargin = data?.items?.length < 2 ? "mb-110px" : "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters); 
  }, [page]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  const searchInputChanged = (value) => {
    setInputs({ ...inputs, search: value });
    setFilters({ ...filters, search: value });

    if(value.length == 0 || value.length === 10){
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        callRequest(setData, filters, { ...filters, search: value });
      }, 500);

      setTimer(newTimer);
    }
      
    if(value.length > 10) message("Please Enter valid goverment ID", false);
  };

  const showBlockConfirmation = (customerId, isBlocked) => {
    blockCustomerPopup({ customerId, isBlocked }, () =>
      callRequest(setData, filters)
    );
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header">
              <div className="col-sm-12 col-md-11 mb-2">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="number"
                    className="form-select"
                    placeholder={t("pages.customers.searchInputPlaceHolder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                    style={{
                      appearance: 'textfield',
                      MozAppearance: 'textfield',
                      WebkitAppearance: 'none', 
                    }}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-1 mb-2">
                <div
                  className="d-flex align-items-center justify-content-end"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    {/* <button
                      className="btn bg-gradient-dark d-flex-center"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Filter"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="19"
                        height="19"
                      >
                        <path d="M3 4c0-.55.45-1 1-1h16c.55 0 1 .45 1 1 0 .22-.07.42-.2.58L14 13.51v6.75c0 .47-.35.9-.82.98-.58.11-1.15-.29-1.15-.89v-6.84L3.2 4.58A.996.996 0 013 4z" />
                      </svg>
                    </button> */}
                  </div>

                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark d-flex-center"
                      onClick={() => exportData(filters, t)}
                      type="button"
                      data-bs-toggle="offcanvas"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Download"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row table-header">
              <div className="col-11 col-md-12 d-flex justify-content-center">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("pages.customers.searchInputPlaceHolder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
                <button
                  className="btn bg-gradient-dark"
                  onClick={() => exportData(filters, t)}
                  type="button"
                  data-bs-toggle="offcanvas"
                  aria-controls="offcanvasExample"
                  data-bs-placement="top"
                  title="Download"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-download"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>

          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table
                className={"table table-hover my-table " + payoutTableMargin}
              >
                <thead>
                  <tr>
                    <th scope="col">{t("inputs.modifiedAt").toString()}</th>
                    <th scope="col">
                      {t("inputs.customers.fullName").toString()}
                    </th>
                    <th scope="col">
                      {t("inputs.customers.email").toString()}
                    </th>
                    <th scope="col">
                      {t("inputs.customers.phoneNumber").toString()}
                    </th>
                    <th scope="col">
                      {t("inputs.customers.dateOfBirth").toString()}
                    </th>
                    <th scope="col">
                      {t("inputs.customers.isActive").toString()}
                    </th>
                    <th scope="col">{t("inputs.status").toString()}</th>
                    <th
                      style={{ minWidth: 200 }}
                      scope="col"
                      className="customer-actions-col"
                    >
                      {t("inputs.actions").toString()}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}

                  {data?.items?.map((item, key) => (
                    <tr key={key}>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.createdAt}
                        </Moment>
                      </td>
                      <td>
                        <strong>{item.fullName}</strong>
                        <p className="gray">
                          <strong>{item.govId}</strong>
                        </p>
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.dateOfBirth}</td>
                      <td>
                        {item.isActive ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="#198754"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="#dc3545"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                          </svg>
                        )}
                      </td>
                      <td>{item.status}</td>
                      <td>
                        {item.isActive ? (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() =>
                              deactivateCustomerPopup(item.govId, () =>
                                callRequest(setData, filters)
                              )
                            }
                            title={t("pages.customers.deactivateTooltip")}
                          >
                            deactivate
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) =>
                              alert(
                                `Customer can't be activated from admin portal.`
                              )
                            }
                            title={t("pages.customers.activateTooltip")}
                          >
                            activate
                          </button>
                        )}
                        {item.isBlocked ? (
                          <button
                            type="button"
                            className="mx-2 btn btn-success"
                            onClick={() =>
                              showBlockConfirmation(item.govId, false)
                            }
                            title={t("pages.customers.unblockTooltip")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-person-check-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                              />
                              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="mx-2 btn btn-danger"
                            onClick={() =>
                              showBlockConfirmation(item.govId, true)
                            }
                            title={t("pages.customers.blockTooltip")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-person-fill-slash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465l3.465-3.465Zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465Zm-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                            </svg>
                          </button>
                        )}
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Button group with nested dropdown"
                        >
                          <div className="btn-group" role="group">
                            <button
                              type="button"
                              className={"btn btn-my-dropdown"}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-three-dots-vertical"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                              </svg>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    navigate("/customers/tracking", {
                                      state: item,
                                    })
                                  }
                                >
                                  {t("pages.customers.tracking")}
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={(e) =>
                                    showCustomerTrackingPopup(item, navigate)
                                  }
                                >
                                  {t("pages.customers.map")}
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={(e) =>
                                    showDetails(t, item, navigate)
                                  }
                                >
                                  {t("pages.customers.details")}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(
                        data?.items,
                        page,
                        data?.totalCount,
                        changePage
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.items?.length < 10 ||
                            data?.totalCount === page
                          }
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
